import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageIntroSection from '../components/PageIntroSection';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../style/company-style.sass';
import { CoreValuesSection } from './services-page';

export const handleDownload = (pdfPath) => {
  const url = pdfPath;  // Replace with your actual PDF file path
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'PharmSight_Brocure.pdf');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const CompanyPageTemplate = ({ pageIntroData, newSection, missionVision, coreValuesData, globalPresence, globalConsulting   }) => {
  const newSectionImage = getImage(newSection.image.childImageSharp.gatsbyImageData);
  const missionImage = getImage(missionVision.missionImage.childImageSharp.gatsbyImageData);
  const visionImage = getImage(missionVision.visionImage.childImageSharp.gatsbyImageData);
  const globalPresenceImage = getImage(globalPresence.backgroundImage.childImageSharp.gatsbyImageData);

  return (
    <div className='page'>
      <PageIntroSection pageIntro={pageIntroData} />
      <div className="company-page section2">
        <div className="new-section">
          <div className="image-container">
            <div>
              {newSectionImage && (
                <GatsbyImage image={newSectionImage} className="image is-suqre" alt="New Section Image" />
              )}
            </div>
            <div className='button-section'>
              <button className="company-page-button" onClick={() => handleDownload(newSection.buttonPath)}>{newSection.buttonLabel}</button>
            </div>
          </div>
          <div className="content-container-section">
            <h2 className="heading" style={{fontSize: "30px",fontWeight:"700",paddingTop:"1rem",lineHeight:"30px"}}>{newSection.title} <span className="span">{newSection.highlight}</span></h2>
            <p className="para" style={{fontSize: "15px", fontWeight: "500",fontFamily:"Montserrat",lineHeight:"22px" }}>{newSection.description}</p>
          </div>
        </div>
        <section className="mission-vision-section section3">
          <div className="mission">
            <div className='mission-img'>
            {missionImage && (
              <GatsbyImage image={missionImage} alt="Mission Icon" className="mission-img" />
            )}
            </div>
            <div className="mission-content">
              <hr style={{backgroundColor: "#E65E27", width:"18%", padding:"0px",margin:"0px"}}/>
              <h3 style={{fontSize: "2rem", fontWeight:"bold", padding: "0px" }}>{missionVision.missionTitle}</h3>
              <p>{missionVision.mission}</p>
            </div>
          </div>
          <div className="vision">
            <div className='vision-img'>
            {visionImage && (
              <GatsbyImage image={visionImage} alt="Vision Icon" className="vision-img" />
            )}
            </div>
            <div className="vision-content">
              <hr style={{backgroundColor: "#34B2E4", width:"18%", alignSelf: "end",marginBottom:"0px"}} />
              <h3 style={{fontSize: "2rem", fontWeight:"bold" }}>{missionVision.visionTitle}</h3>
              <p>{missionVision.vision}</p>
            </div>
          </div>
        </section>
        <CoreValuesSection coreValues={coreValuesData} />
        <div className="global-presence-section fit-page section5">
          <div className="global-heading">
          <h1>
              {globalPresence.title.map((item, index) => (
                <span key={index} style={{ color: item.color, fontWeight: item.fontSize}}>{item.fragment} </span>
              ))}
          </h1>
          </div>
          <div className="global-presence-image" style={{backgroundSize: "100% 100%"}}>
            {globalPresenceImage && (
              <GatsbyImage image={globalPresenceImage} alt="Global Presence Background" className="global-img" />
            )}
              {globalPresence.markers.map((marker, index) => {
              const markerIcon = getImage(marker.icon.childImageSharp.gatsbyImageData);
              return (
                <div key={index} className="marker" style={{position:"absolute", left: marker.left, top: marker.top, color:marker.textColor, backgroundColor: marker.color, borderRadius:"2px", fontWeight:marker.fontSize}}>
                  {markerIcon && (
                    <GatsbyImage image={markerIcon}  alt={marker.name} className="marker-icon" />
                  )}
                  <span className="marker-name">{marker.name}</span>
                </div>
              );
            })} 
          </div>
        </div>
        <section className="global-consulting-section section6">
          <div className='heading'
         style={{textTransform:"none", width:"80%",marginBottom:"0.5rem" }}>{globalConsulting.title} <span className="highlight">{globalConsulting.highlight}</span>
          </div>
          <div className="global-consulting-grid">
            {globalConsulting.details.map((detail, index) => (
              <div key={index} className="grid-item">
                <p>{detail.content}</p>
                <h3>{detail.highlight}</h3>
                {detail.subtext && <p>{detail.subtext}</p>}
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

CompanyPageTemplate.propTypes = {
  pageIntroData: PropTypes.object.isRequired,
  newSection: PropTypes.object.isRequired,
  missionVision: PropTypes.object.isRequired,
  coreValuesData: PropTypes.object.isRequired,
  globalPresence: PropTypes.object.isRequired,
  globalConsulting: PropTypes.object.isRequired,
};

const CompanyPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const pageIntroData = post?.frontmatter?.companyPage?.aboutUs?.pageIntroData;
  const newSection = post?.frontmatter?.companyPage?.newSection;
  const missionVision = post?.frontmatter?.companyPage?.missionVision;
  const coreValuesData = post?.frontmatter?.companyPage?.coreValues; 
  const globalPresence = post?.frontmatter?.companyPage?.globalPresence;
  const globalConsulting = post?.frontmatter?.companyPage?.globalConsulting;

  return (
    <Layout>
      {pageIntroData && newSection && missionVision && coreValuesData && globalPresence ? (
        <CompanyPageTemplate 
          pageIntroData={pageIntroData} 
          newSection={newSection} 
          missionVision={missionVision} 
          coreValuesData={coreValuesData} 
          globalPresence={globalPresence}
          globalConsulting={globalConsulting}
        />
      ) : (
        <div>No data available</div>
      )}
    </Layout>
  );
};

CompanyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default CompanyPage;


export const pageQuery = graphql`
  query CompanyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "company-page" } }) {
      frontmatter {
        companyPage {
          aboutUs {
            pageIntroData {
              desktop {
                title {
                  fragment
                  color
                }
                backgroundVideo
                backgroundImage {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
                description
                buttonLabel
                buttonColor
                buttonPath
                buttonSection
                styles {
                  sectionHeight
                  titleFontSize
                  titleFontWeight
                  titleLineHeight
                  descriptionFontSize
                  descriptionFontWeight
                  descriptionLineHeight
                  contentWidth
                  contentPadding
                  buttonPadding
                  buttonFontSize
                  buttonFontWeight
                }
              }
              mobile {
                title {
                  fragment
                  color
                }
                backgroundVideo
                backgroundImage {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                description
                buttonLabel
                buttonColor
                buttonPath
                buttonSection
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  descriptionFontWeight
                  descriptionLineHeight
                  contentPadding
                  contentWidth
                }
              }
            }
          }
          newSection {
            title
            highlight
            description
            buttonLabel
            buttonColor
            buttonPath
            image {
              childImageSharp {
                gatsbyImageData(width: 600, quality: 80, layout: CONSTRAINED)
              }
            }
          }
          missionVision {
            mission
            vision
            missionTitle
            visionTitle
            missionImage {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            visionImage {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          coreValues { 
            title {
              mobileTitle {
                fragment
                color
              }
              desktopTitle {
                fragment
                color
              }
            }
            emptyImg {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            buttonPath
            coreValuesList {
              icon {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              mobileIcon {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              mobileView
              title
              description
            }
          }
          globalPresence {
            title {
              fragment
              color
              fontSize
            }
            backgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, width:800)
              }
            }
            markers {
              name
              icon {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FIXED)
                }
              }
              color 
              textColor
              fontSize 
              left
              top
            }
          }  
          globalConsulting {
            title
            highlight
            details {
              content
              highlight
              subtext
            }
          }
        }
      }
    }
  }
`;
