import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import useWindowSize from '../hooks/useWindowSize';
import '../style/global-style.sass';
import '../style/services.sass';
import PageIntroSection from '../components/PageIntroSection';
import UseGoToSection from '../components/UseGoToSection';
import { handleDownload } from './company-page';

// AboutServicesSection Component
const AboutServicesSection = ({ aboutServices }) => {
  const width = useWindowSize();
  return (
    <section class="section is-small">
      <div className="about-services-section">
        <div>
          <h3 className='about-services-section-title'>{aboutServices.title}</h3>
        </div>
        <div>
          <p className="about-services-section-description">{aboutServices.description}</p>
        </div>
        <div>
          <img className="about-services-section-image" src={aboutServices.image} alt="About Services" />
        </div>
      </div>
    </section>
)};

AboutServicesSection.propTypes = {
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  ).isRequired,
};


//Helper component 
const TitleFragment = ({ fragment, color }) => (
  <span  className='solutions-m-title' style={{ color }}>{fragment + " "}</span>
);
// SolutionsSection Component
export const SolutionsSection = ({ solutions }) => {

  const [activeTab , setActiveTab] = useState(0);
  
  return (
    <section class="section is-small section3">
      <div className='solutions-main-container1'>
        <div className='solutions-main-container'>
          <div className='solution-title'>
            {solutions.title.map((value,ind) => (
              <TitleFragment key={ind} {...value}/>
            ))}
          </div>
          <div class="tabs is-centered">
            <ul>
                {solutions.solutionsList.map((item,index) => (
                  <li className= {index === activeTab ? 'setcolor' : ''} key={index} onClick={() => {setActiveTab(index);}}>
                    <a>
                      <span className= {index === activeTab ? 'has-text-weight-medium setcolor solution-tab-title': 'has-text-weight-medium solution-tab-title'}>{item.title}</span>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          {solutions.solutionsList.map((item,i) => {
            let solutionImage = getImage(item.icon)
            return (
            <div className='solutions-list' style={{ display: i === activeTab ? 'flex' : 'none' }} key={i}>
                <div className='solutions-list-sub1'>
                    <div className='solutions-list-sub1-title-width'>
                      <span className='solutions-list-sub1-title has-text-weight-semibold'>{item.title1}</span>
                      <span className='solutions-list-sub1-title2 has-text-weight-semibold' style={{color: `${solutions.title2color}`}}>{' '}{item.title2}</span>
                    </div>
                    <p className='solutions-list-p has-text-weight-medium'>{item.description}</p>
                    <Link to={item.path}><button className='solutions-list-button has-text-weight-medium'>EXPLORE MORE</button></Link>
                </div>
                <div className='solutions-list-sub2'>
                  <GatsbyImage image={solutionImage} className="solutions-list-sub-2-img" alt="Loading..."/>
                </div>
            </div>
          )})} 
        </div>
      </div>
    </section>
  );
};



SolutionsSection.propTypes = {
  solutions: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        frament: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
      })
    ).isRequired,
    backgroundColor: PropTypes.string.isRequired,
    title2color: PropTypes.string.isRequired,
    solutionsList: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        title: PropTypes.string.isRequired,
        title1: PropTypes.string.isRequired,
        title2: PropTypes.string.isRequired,
        titleColor: PropTypes.string.isRequired,
        mobileTitle: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};

// CoreValuesSection Component
export const CoreValuesSection = ({ coreValues }) => {
  const { width } = useWindowSize();
  return (
  <div class="section4" style={{backgroundColor: "black" ,display: 'flex' ,flexDirection: 'column', justifyContent: 'center' ,alignItems: 'center'}}>
    <div className="core-values-section">

      <div className="core-values-brochure-section" style={{cursor: "pointer"}}>
        <GatsbyImage image={getImage(coreValues.emptyImg)} alt='PharmSight Brochure' style={{width: '100%' , height:'100%' , borderRadius: '1rem'}}/>
        <div className="core-values-brochure-download" onClick={() => handleDownload(coreValues.buttonPath)}>DOWNLOAD BROCHURE</div>
      </div>

      <div className="core-values-content-section">
        <div className="core-values-section-title">
          <h2 className="solutions-header">
            {width > 768 ? (coreValues.title.desktopTitle.map((part, index) => (
              <span style={{ color: part.color }}>{part.fragment + " "}</span>
            ))) : (
              coreValues.title.mobileTitle.map((part, index) => (
                <span style={{ color: part.color }}>{part.fragment + " "}</span>
              ))
            )}
          </h2>
        </div>
        <div className="core-values-content">
          {coreValues.coreValuesList.map((coreValue, index) => {
            let coreValueIcon = getImage(width > 768 ? coreValue.icon : coreValue.icon)
            return (width > 768 ? true : true) && (
              <>
                {(width <= 768 && index) ? <hr /> : <></>}
                <div key={index} className="core-value">
                  <div className="core-value-icon">
                    <GatsbyImage image={coreValueIcon} style={{width: width > 768 ? "55%": "clamp(3rem, 2vw, 20vw)"}} alt="Core Value"/>
                  </div>
                  <div className="core-value-container">
                    <div className="core-value-title">{coreValue.title}</div>
                    <div className="core-value-description">{coreValue.description}</div>
                  </div>
                </div>
              </>
          )})}
        </div>
      </div>
    </div>
  </div>
)};

CoreValuesSection.propTypes = {
  coreValues: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

// CI Principles Section Component
const CIPrinciplesSection = ({ principles }) => {
  const ciPrinciplesImage = getImage(principles.image)
  return (
  <section class="section is-small section2" style={{display: 'flex' , justifyContent: 'center' , paddingTop: '7%' , paddingBottom: '6%'}}>
    <div className="ci-principles-section">
      <div className="ci-principles-content-section">
        <h2 className="ci-principles-title">
          {principles.title.map((part, index) => (
            <span style={{ color: part.color }}>{part.fragment + " "}</span>
          ))}
        </h2>
        <p className='ci-principles-des' style={{fontWeight: "500", marginTop: "10px" , color: 'black', fontFamily: 'Montserrat' }}>{principles.description}</p>
      </div>
      <div className="ci-principles-image-section">
        <GatsbyImage image={ciPrinciplesImage} className="ci-principles-image image is-square" style={{paddingTop: "0%"}} alt="CI Principles"/>
      </div>
    </div>
  </section>
)};

CIPrinciplesSection.propTypes = {
  principles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired
        })
      ).isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  ).isRequired,
};

// Main Page Component
export const ServicesPageTemplate = ({ pageIntroData, aboutServices, solutionsData, coreValuesData, ciPrinciplesData }) => {
  return (
  <div className="services-page">
    <PageIntroSection pageIntro={pageIntroData} />
    <CIPrinciplesSection principles={ciPrinciplesData} />
    <SolutionsSection solutions={solutionsData} />
    <CoreValuesSection coreValues={coreValuesData} />
    <UseGoToSection />
  </div>
)};

ServicesPageTemplate.propTypes = {
  pageIntroData: PropTypes.shape({
    desktop: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired,
    mobile: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      backgroundVideo: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  aboutServices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  ).isRequired,
  solutionsData: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        frament: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
      })
    ).isRequired,
    backgroundColor: PropTypes.string.isRequired,
    title2color: PropTypes.string.isRequired,
    solutionsList: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        title: PropTypes.string.isRequired,
        title1: PropTypes.string.isRequired,
        title2: PropTypes.string.isRequired,
        titleColor: PropTypes.string.isRequired,
        mobileTitle: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  coreValuesData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  ciPrinciplesData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired
        })
      ).isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  ).isRequired,
};

const ServicesPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <ServicesPageTemplate
        pageIntroData={post.frontmatter.pageIntroData}
        aboutServices={post.frontmatter.aboutServices}
        solutionsData={post.frontmatter.solutions}
        coreValuesData={post.frontmatter.coreValues}
        ciPrinciplesData={post.frontmatter.ciPrinciples}
      />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPage {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      frontmatter {
        pageIntroData {
          desktop {
            title {
              fragment
              color
            }
            backgroundVideo
            description
            buttonLabel
            buttonColor
            buttonPath
            styles {
              sectionHeight
              titleFontSize
              titleFontWeight
              titleLineHeight
              descriptionFontSize
              descriptionFontWeight
              descriptionLineHeight
              contentWidth
              contentPadding
              buttonPadding
              buttonFontSize
              buttonFontWeight
            }
          }
          mobile {
            title {
              fragment
              color
            }
            backgroundVideo
            description
            buttonLabel
            buttonColor
            buttonPath
            styles {
              sectionHeight
              titleFontSize
              titleFontWeight
              titleLineHeight
              descriptionFontSize
              descriptionFontWeight
              descriptionLineHeight
              contentWidth
              contentPadding
            }
          }
        }
        aboutServices {
          title
          description
          image
        }
        solutions {
          title {
            fragment
            color
          }
          backgroundColor
          title2color
          solutionsList {
            icon {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            title
            title1
            title2
            titleColor
            mobileTitle
            description
            path
          }
        }
        coreValues {
          title {
            mobileTitle {
              fragment
              color
            }
            desktopTitle {
              fragment
              color
            }
          }
          emptyImg {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          buttonPath
          coreValuesList {
            icon {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            mobileIcon {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            mobileView
            title
            description
          }
        }
        ciPrinciples {
          title {
            fragment
            color
          }
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`;
